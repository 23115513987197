import { LegacyRef, useEffect, useState, useRef } from "react";

// DEPENDENCIES
import QRCode from "qrcode";
import { useLocation, useSearchParams } from "react-router-dom";
import { doc, getFirestore, onSnapshot } from "@firebase/firestore";

// UTILS
import useWindowWidth from "../../hooks/useWindowWidth";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { logAnalyticsAndHotjarEvent, persistor } from "../../main";
import { livenessStep, saveLivenessDataToFirestore } from "./utils/utils";
import { getLivenessSessionId } from "../../connectors/connectors";
import { closeModal, openModal } from "../../reducers/modalReducer";
import { updateToken } from "../../reducers/authReducer";
import { onUpdateActiveTemplate } from "../../reducers/activeTemplateReducer";
import {
  showToastMessage,
  ToastMessage,
} from "@almafintech/react-components/ToastMessage";
import {
  onUpdateLivenessState,
  onUpdateSessionId,
} from "../../reducers/livenessReducer";
import {
  onUpdatePrimaryButton,
  onUpdateSecondaryButton,
  onUpdateTertiaryButton,
} from "../../reducers/buttonsReducer";
import {
  onUpdateOnClose,
  onUpdateQrImage,
  onUpdateScannedQr,
  onUpdateSuccessMessage,
} from "../../reducers/qrReducer";
import { addOneValidation } from "../../reducers/validationsReducer";
import juridicalTemplate from "../../templates/allaria/juridical";
import physicalTemplate from "../../templates/allaria/physical";
import signatoryTemplate from "../../templates/allaria/signatory";
import { qrStyle } from "../../utils";
import useForm from "../../hooks/useForm";

// TYPES
import { LivenessData } from "./interfaces/types";
import {
  ComponentProps,
  ErrorResponse,
  ValidationErrorResponse,
} from "../../types";

// COMPONENTS
import Layout from "../Layout/Layout";
import Container from "../Container/Container";
import FormContainer from "../FormContainer/FormContainer";
import QRModal from "../QRModal/QRModal";
import { Player } from "@lottiefiles/react-lottie-player";
import { Button } from "@almafintech/react-components/Button";
import { InputCopy } from "@almafintech/react-components/InputCopy";
import MinimumRequirementsModal from "./MinimumRequirementsModal/MinimumRequirementsModal";
import Liveness from "./Liveness";

import styles from "./LivenessValidationCard.module.scss";

const LivenessValidationCard = ({ formik }: ComponentProps) => {
  const { copyLinkContainer, externalLink, withQr } = styles;

  const { handleBack, handleContinue } = useForm(formik);

  const [error, setError] = useState<string | null>(null);

  const playerRef: LegacyRef<Player> | undefined = useRef(null);

  const dispatch = useAppDispatch();

  const { livenessState, sessionId } = useAppSelector(
    (state) => state.liveness
  );
  const { formValues, draftUUID } = useAppSelector(
    (state) => state.onboardingData
  );
  const { token: tokenStore } = useAppSelector((state) => state.authentication);
  const { cuil: renaperCuit } = useAppSelector((state) => state.renaper);
  const { onboardingType, favicon, icon } = useAppSelector(
    (state) => state.activeTemplate
  );

  //Firestore
  const db = getFirestore();
  const attemptsLimit = 5;

  const location = useLocation();
  const [searchParams] = useSearchParams();
  const isExternalLink = location.pathname.includes("identidad");
  const urlUUID = searchParams.get("uuid");
  const urlToken = searchParams.get("token");
  const urlTemplate = searchParams.get("template");
  const urlCuit = searchParams.get("cuit");
  const urlEmail = searchParams.get("email") || "";
  const continueWithQR = searchParams.get("withQR") === "true";
  const uploadedSuccessfully = searchParams.get("success") === "true";

  const isJuridicalOnboarding =
    location.pathname.includes("empresarial") ||
    location.pathname.includes("firmante") ||
    urlTemplate === "JURIDICAL";

  const uuid = urlUUID || draftUUID;
  const token = urlToken || tokenStore;
  const template = urlTemplate || onboardingType;
  const inputCuit = formValues?.cuit;
  const cuit = urlCuit || inputCuit || renaperCuit;
  const email =
    decodeURIComponent(urlEmail).replace(/ /g, "+") || formValues?.["email"];
  const livenessLink = `${window.location.origin}/identidad?uuid=${uuid}&token=${token}&template=${template}&cuit=${cuit}`;

  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [livenessData, setLivenessData] = useState<LivenessData>({
    dni: formValues?.["identityDocument"],
    gender: formValues?.["gender"],
    email,
    attemptsDesktop: 0,
    attemptsMobile: 0,
    scannedQR: false,
    isValid: false,
    faceLivenessDetection: "",
    facialRecognition: "",
    documentName:
      template === "SIGNATORY"
        ? "livenessValidationSignatory"
        : "livenessValidation",
    documentId: template === "SIGNATORY" ? `${uuid}-${email}` : uuid,
  });

  const { attemptsDesktop, attemptsMobile, scannedQR, isValid } = livenessData;

  const dimensions = useWindowWidth();
  const isMobile = dimensions.width < 768;

  const reachedAttemptsLimit =
    (continueWithQR || isMobile ? attemptsMobile : attemptsDesktop) >=
    attemptsLimit;

  const handleError = (error?: string) => {
    setError(error ?? "FAILED_VALIDATION");
    dispatch(onUpdateLivenessState("NOT_VALIDATED"));
  };

  const handleOpenQr = () => {
    dispatch(onUpdateScannedQr(false));
    dispatch(openModal({ name: "modal-qr" }));
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(livenessLink);
    showToastMessage("Link copiado al portapapeles.", {
      containerId: "layoutPage",
      type: "success",
    });
  };

  const loadTemplate = async () => {
    if (urlTemplate === "PHYSICAL") {
      const template = await physicalTemplate();
      dispatch(onUpdateActiveTemplate(template));
    }

    if (urlTemplate === "JURIDICAL") {
      const template = await juridicalTemplate();
      dispatch(onUpdateActiveTemplate(template));
    }

    if (urlTemplate === "SIGNATORY") {
      const template = await signatoryTemplate();
      dispatch(onUpdateActiveTemplate(template));
    }
  };

  // Get template to load favicon
  useEffect(() => {
    if (!onboardingType) loadTemplate();
  }, []);

  useEffect(() => {
    if (favicon) {
      const newLink = document.createElement("link");
      newLink.rel = "icon";
      newLink.type = "image/png";
      newLink.href = favicon;
      document.head.appendChild(newLink);
    }
  }, [favicon]);

  useEffect(() => {
    if (urlToken) dispatch(updateToken(urlToken));
  }, [urlToken]);

  useEffect(() => {
    if (isValid && livenessState !== "VALIDATED") {
      dispatch(closeModal());
      saveLivenessDataToFirestore({
        ...livenessData,
        scannedQR: false,
        uuid,
      });
      logAnalyticsAndHotjarEvent("step_identity_completed_pf");
      logAnalyticsAndHotjarEvent("identity_verification_completed");

      dispatch(onUpdateLivenessState(continueWithQR ? "VALIDATED" : null));
      setTimeout(() => {
        playerRef.current?.play();
      }, 1000);
    }
  }, [isValid, livenessState]);

  useEffect(() => {
    error &&
      (error === "CAMERA_ACCESS_ERROR"
        ? dispatch(onUpdateLivenessState("CAMERA_ACCESS_REQUIRED"))
        : handleError());
  }, [error]);

  useEffect(() => {
    if (error && !isLoadingData) {
      const updatedAttempts = isExternalLink
        ? {
            attemptsMobile: attemptsMobile + 1,
          }
        : {
            attemptsDesktop: attemptsDesktop + 1,
          };
      saveLivenessDataToFirestore({
        ...livenessData,
        ...updatedAttempts,
        uuid,
      });
    }
  }, [error, isLoadingData]);

  useEffect(() => {
    if (livenessState && !isExternalLink) {
      if (["INITIAL", "NOT_VALIDATED"].includes(livenessState)) {
        if (!isMobile) {
          dispatch(
            onUpdateSecondaryButton({
              text: "Validar con mi teléfono",
              onClick: () => {
                setError(null);
                saveLivenessDataToFirestore({
                  ...livenessData,
                  scannedQR: false,
                  uuid,
                });
                handleOpenQr();
              },
              disabled: false,
              show: true,
            })
          );
        } else {
          dispatch(
            onUpdateSecondaryButton({
              text: "Volver",
              disabled: false,
              isLoading: false,
              onClick: handleBack,
              show: true,
            })
          );
          onUpdateLivenessState(null);
        }
      }

      if (isMobile) {
        dispatch(
          onUpdateTertiaryButton({
            show: false,
          })
        );
      } else {
        dispatch(
          onUpdateTertiaryButton({
            text: "Volver",
            disabled: false,
            isLoading: false,
            onClick: handleBack,
            show: true,
          })
        );
        onUpdateLivenessState(null);
      }

      switch (livenessState) {
        case "INITIAL":
          dispatch(
            onUpdatePrimaryButton({
              text: "Comenzar captura",
              disabled: false,
              isLoading: !sessionId,
              onClick: () => dispatch(onUpdateLivenessState("DETECTOR")),
              show: true,
            })
          );
          break;

        case "DETECTOR":
          dispatch(
            onUpdatePrimaryButton({
              disabled: true,
              show: true,
            })
          );
          break;

        case "NOT_VALIDATED":
          dispatch(
            onUpdatePrimaryButton({
              text: "Reintentar captura",
              isLoading: false,
              onClick: () => {
                setError(null);
                dispatch(onUpdateSessionId(""));
                dispatch(onUpdateLivenessState("INITIAL"));
              },
              disabled: false,
              show: true,
            })
          );

          reachedAttemptsLimit &&
            dispatch(
              onUpdatePrimaryButton({
                disabled: true,
                show: true,
              })
            );
          break;

        case "VALIDATED":
          dispatch(
            onUpdatePrimaryButton({
              text: "Continuar",
              disabled: false,
              isLoading: false,
              type: "button",
              onClick: handleContinue,
              show: true,
            })
          );
          dispatch(
            onUpdateSecondaryButton({
              text: "Volver",
              disabled: false,
              isLoading: false,
              onClick: handleBack,
              show: true,
            })
          );

          dispatch(
            onUpdateTertiaryButton({
              show: false,
            })
          );

          onUpdateLivenessState(null);
          break;
        default:
          break;
      }
    }
  }, [livenessState, sessionId, reachedAttemptsLimit, isMobile]);

  useEffect(() => {
    livenessState === "INITIAL" &&
      (isExternalLink || !sessionId) &&
      getLivenessSessionId()
        .then((res) => {
          const sessionId = res.data.sessionId;
          dispatch(onUpdateSessionId(sessionId));
        })
        .catch((error: ErrorResponse | ValidationErrorResponse) => {
          console.log(error);
          handleError("SESSION_ID_ERROR");
        });
    if (livenessState === "VALIDATED") {
      dispatch(
        onUpdatePrimaryButton({
          text: "Continuar",
          disabled: false,
          isLoading: false,
          type: "button",
          onClick: handleContinue,
          show: true,
        })
      );
    }
  }, [livenessState]);

  useEffect(() => {
    if (!isLoadingData && !livenessState && !isValid) {
      dispatch(
        onUpdateLivenessState(
          reachedAttemptsLimit ? "NOT_VALIDATED" : "INITIAL"
        )
      );
    }
  }, [isLoadingData, reachedAttemptsLimit, livenessState]);

  useEffect(() => {
    const firestoreData = { ...livenessData, uuid };
    //From form
    const { dni, gender, email, documentName, documentId } = livenessData;

    try {
      const unsub = onSnapshot(doc(db, documentName, documentId), (doc) => {
        if (doc.exists()) {
          const data = doc.data() as LivenessData;
          if (
            (!gender && !dni) ||
            (data.gender === gender && data.dni === dni && data.email)
          ) {
            setLivenessData(data);
            setIsLoadingData(false);
          } else {
            saveLivenessDataToFirestore({
              ...data,
              dni,
              gender,
              email,
              uuid,
            });
            setIsLoadingData(false);
          }
          if (
            data.faceLivenessDetection !== "" &&
            data.facialRecognition !== ""
          ) {
            dispatch(
              addOneValidation({
                name: "FACE_LIVENESS_DETECTION",
                externalCode: data.faceLivenessDetection,
              })
            );
            dispatch(
              addOneValidation({
                name: "FACIAL_RECOGNITION",
                externalCode: data.facialRecognition,
              })
            );
            setIsLoadingData(false);
          }
        } else {
          saveLivenessDataToFirestore(firestoreData);
          setIsLoadingData(false);
        }
      });
      return unsub;
    } catch (error) {
      console.log("Error in liveness validation");
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (continueWithQR && !isLoadingData) {
      logAnalyticsAndHotjarEvent("qr_code_scanned_selfie");
      saveLivenessDataToFirestore({
        ...livenessData,
        scannedQR: true,
        uuid,
      });
      localStorage.clear();
    }
  }, [continueWithQR, isLoadingData]);

  // Set QR link
  useEffect(() => {
    QRCode.toDataURL(
      livenessLink.concat(`&withQR=true&email=${formValues?.["email"]}`),
      qrStyle
    ).then((img) => dispatch(onUpdateQrImage(img)));
    dispatch(
      onUpdateSuccessMessage("Ya podés validar tu identidad desde el teléfono.")
    );
    dispatch(
      onUpdateOnClose(() =>
        saveLivenessDataToFirestore({
          ...livenessData,
          scannedQR: false,
          uuid,
        })
      )
    );
  }, [livenessData]);

  useEffect(() => {
    dispatch(onUpdateScannedQr(scannedQR));
  }, [scannedQR]);

  useEffect(() => {
    if (isExternalLink) {
      // Reset redux persist
      persistor.pause();
      persistor.flush().then(() => {
        return persistor.purge();
      });
      localStorage.clear();
    }
  }, [isExternalLink]);

  return (
    <section className="h-full">
      {!continueWithQR && <QRModal />}
      <MinimumRequirementsModal />
      {isExternalLink ? (
        <Layout
          hideStepper
          hideLogo={continueWithQR}
          hideSupportButton={continueWithQR}
          className={continueWithQR ? withQr : ""}
        >
          <ToastMessage messageId="formAlert" position="top-center" />
          <Container className="h-auto">
            <FormContainer
              icon={icon}
              currentStep={!continueWithQR ? livenessStep : undefined}
              currentSubStep={1}
              classNameFormContainer={continueWithQR ? "h-auto" : ""}
              className={`${isExternalLink ? externalLink : ""}`}
              hideHeader={continueWithQR}
            >
              <Liveness
                uuid={uuid}
                sessionId={sessionId}
                isLoadingData={isLoadingData}
                livenessState={livenessState}
                livenessData={livenessData}
                reachedAttemptsLimit={reachedAttemptsLimit}
                isExternalLink={isExternalLink}
                continueWithQR={continueWithQR}
                isMobile={isMobile}
                error={error}
                handleError={handleError}
                playerRef={playerRef}
                isJuridicalOnboarding={isJuridicalOnboarding}
                cuil={cuit}
              />
            </FormContainer>
          </Container>
          {!uploadedSuccessfully && (
            <div className="flex gap-2 w-full justify-center">
              {isExternalLink &&
                !continueWithQR &&
                ["INITIAL", "NOT_VALIDATED", null].includes(livenessState) && (
                  <Button
                    variant="secondary"
                    text="Validar con mi teléfono"
                    onClick={handleOpenQr}
                  />
                )}
              {isExternalLink &&
                !reachedAttemptsLimit &&
                !["VALIDATED", null].includes(livenessState) && (
                  <Button
                    text={
                      livenessState === "NOT_VALIDATED"
                        ? "Reintentar captura"
                        : "Comenzar captura"
                    }
                    onClick={() => {
                      setError(null);
                      if (livenessState !== "NOT_VALIDATED")
                        logAnalyticsAndHotjarEvent(
                          "identity_verification_started"
                        );
                      dispatch(
                        onUpdateLivenessState(
                          livenessState === "INITIAL" ? "DETECTOR" : "INITIAL"
                        )
                      );
                      if (livenessState === "NOT_VALIDATED")
                        dispatch(onUpdateSessionId(""));
                    }}
                    isLoading={!sessionId}
                    disabled={
                      error === "CAMERA_FRAMERATE_ERROR" ||
                      (!!livenessState && livenessState === "DETECTOR")
                    }
                    style={isMobile ? { paddingBottom: "1.25rem" } : undefined}
                    fullWidth={continueWithQR}
                    size={isMobile ? "mobile" : "desktop"}
                  />
                )}
            </div>
          )}
        </Layout>
      ) : (
        <>
          <Liveness
            uuid={uuid}
            sessionId={sessionId}
            isLoadingData={isLoadingData}
            livenessState={livenessState}
            livenessData={livenessData}
            reachedAttemptsLimit={reachedAttemptsLimit}
            isExternalLink={isExternalLink}
            continueWithQR={continueWithQR}
            isMobile={isMobile}
            error={error}
            handleError={handleError}
            playerRef={playerRef}
            isJuridicalOnboarding={isJuridicalOnboarding}
            cuil={cuit}
          />
          {isJuridicalOnboarding && livenessState === "INITIAL" && (
            <div className={copyLinkContainer}>
              <h4>
                O compartí el siguiente link y esperá a que termine la
                validación.
              </h4>
              <InputCopy value={livenessLink} onClick={handleCopyLink} />
            </div>
          )}
        </>
      )}
    </section>
  );
};

export default LivenessValidationCard;
