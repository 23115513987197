import { ReactNode } from "react";
import { ToastMessage } from "@almafintech/react-components/ToastMessage";
import Stepper from "../Stepper/Stepper";
import SupportButton from "../SupportButton/SupportButton";
import useWindowWidth from "../../hooks/useWindowWidth";

import styles from "./Layout.module.scss";
import {
  getCompanyData,
  isToros,
  isWina,
  isLandToken,
  isLaBragadense,
  isAllariaOnboarding,
  isAllariaMasOnboarding,
} from "../../utils";

import torosCapitalLogo from "../../assets/images/logos/toros-capital.png";
import winaLogo from "../../assets/images/logos/wina.svg";
import landTokenLogo from "../../assets/images/logos/landtoken.svg";
import laBragadenseLogo from "../../assets/images/logos/labragadense.svg";
import { useAppSelector } from "../../app/hooks";

interface Props {
  children: ReactNode;
  hideStepper?: boolean;
  hideLogo?: boolean;
  hideSupportButton?: boolean;
  className?: string;
}

const Layout = ({
  children,
  className,
  hideStepper,
  hideLogo,
  hideSupportButton,
}: Props) => {
  const {
    container,
    header,
    body,
    button,
    companyLogo,
    torosLogo,
    winaLogoStyles,
    landToken,
    laBragadense,
  } = styles;

  const dimensions = useWindowWidth();
  const isMobile = dimensions.width < 768;

  const { formValues } = useAppSelector(({ onboardingData }) => onboardingData);

  const { logo, logoClassname, companyName } = getCompanyData();

  return (
    <div className={container}>
      <div className={header}>
        {isLaBragadense(formValues) &&
          !hideLogo &&
          isAllariaMasOnboarding() && (
            <img
              className={laBragadense}
              src={laBragadenseLogo}
              alt="La Bragadense logo"
            />
          )}
        {isLandToken(formValues) && !hideLogo && isAllariaOnboarding() && (
          <img
            className={landToken}
            src={landTokenLogo}
            alt="Land Token logo"
          />
        )}
        {isToros(formValues) && !hideLogo && isAllariaOnboarding() && (
          <img
            className={torosLogo}
            src={torosCapitalLogo}
            alt="Toros Capital logo"
          />
        )}
        {isWina(formValues) && !hideLogo && isAllariaOnboarding() && (
          <img className={winaLogoStyles} src={winaLogo} alt="Wina logo" />
        )}
        {!hideStepper && !isMobile && <Stepper />}
        {!hideLogo && (
          <img
            className={`${companyLogo} ${
              logoClassname ? styles[logoClassname] : ""
            }`}
            src={logo}
            alt={`${companyName} logo`}
          />
        )}
      </div>
      <ToastMessage messageId="layoutPage" position="top-right" />
      <div className={`${body} ${className}`}>{children}</div>
      {!hideSupportButton && <SupportButton className={button} />}
    </div>
  );
};

export default Layout;
