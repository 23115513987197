import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

import { openModal } from "../../reducers/modalReducer";
import { Input, InputProps } from "@almafintech/react-components/Input";
import { InputSelect } from "@almafintech/react-components/InputSelect";
import { InputPhone } from "@almafintech/react-components/InputPhone";
import { FileData, InputFile } from "@almafintech/react-components/InputFile";
import { InputTextArea } from "@almafintech/react-components/InputTextArea";
import { InputAddress } from "@almafintech/react-components/InputAddress";
import { RadioGroup } from "@almafintech/react-components/RadioGroup";
import { Tooltip } from "@almafintech/react-components/Tooltip";
import { NewDatePicker } from "@almafintech/react-components/NewDatePicker";
import {
  ConditionalBool,
  Field,
  SegmentedInputOption,
  Tooltip as TooltipType,
} from "../../types";
import { FormikProps, FormikValues } from "formik";
import SegmentedOptionsInput from "../SegmentedOptionsInput/SegmentedOptionsInput";
import useWindowWidth from "../../hooks/useWindowWidth";
import {
  getIsConditionallyTrue,
  getNestedValue,
  isSchemaRequired,
} from "./utils";
import tooltipIcon from "../../assets/images/icons/ui/tooltip.svg";
import { parseDate, CalendarDate } from "@internationalized/date";

import styles from "./TemplateInterpreter.module.scss";
import { calendarDateToString, stringToCalendarDate } from "../../app/utils";

interface ColumnsWrapperProps {
  columns: number | undefined;
  children: React.ReactNode;
}

interface TemplateInputSelectorProps {
  currentStepFields?: Field[];
  field: Field;
  formik: FormikProps<FormikValues>;
}

const ColumnsWrapper = ({ columns, children }: ColumnsWrapperProps) => {
  const { columnsWrapper, twoColumn, threeColumn } = styles;

  const dimensions = useWindowWidth();
  const isMobile = dimensions.width < 768;

  const columnsClassNames = [];

  if (!isMobile && columns) {
    columnsClassNames.push(
      columns === 2 ? twoColumn : columns === 3 ? threeColumn : ""
    );
  }

  return (
    <div className={`${columnsWrapper} ${columnsClassNames.join(" ")}`}>
      {children}
    </div>
  );
};

interface ConditionalResetAndScrollTo {
  conditionalBoolCurrentField: ConditionalBool[] | undefined;
  boolFieldsWithSameConditionalField: ConditionalBool[] | undefined;
  values: FormikValues;
  setFieldValue: (field: string, value: unknown) => void;
  scrollToElementName: (name: string, isFileElement?: boolean) => void;
}

const conditionalResetAndScrollTo = ({
  conditionalBoolCurrentField,
  boolFieldsWithSameConditionalField,
  values,
  setFieldValue,
  scrollToElementName,
}: ConditionalResetAndScrollTo) => {
  // Scroll to conditional input to be visible
  if (conditionalBoolCurrentField && conditionalBoolCurrentField.length > 0) {
    setTimeout(() => {
      // If the current field triggers another conditional field
      for (const field of conditionalBoolCurrentField) {
        // If conditionalField have multiple boolFields, reset it only if all of them have no value
        if (
          Array.isArray(boolFieldsWithSameConditionalField) &&
          boolFieldsWithSameConditionalField?.length > 0
        ) {
          const allBoolFieldsHaveNoValue = [
            ...conditionalBoolCurrentField,
            ...boolFieldsWithSameConditionalField,
          ].every(
            (conditional) =>
              !getNestedValue(values, conditional.boolField as string)
          );

          if (allBoolFieldsHaveNoValue) {
            for (const field of boolFieldsWithSameConditionalField) {
              setFieldValue(field?.conditionalField, "");
            }
          }
        } else {
          // The conditional file is emptied
          setFieldValue(field?.conditionalField, "");
        }
      }

      if (!conditionalBoolCurrentField[0]?.dontScrollTo) {
        scrollToElementName(conditionalBoolCurrentField[0]?.conditionalField);
      }
    }, 200);
  }
};

const scrollToElementName = (name: string, isFileElement?: boolean) => {
  const element = isFileElement
    ? document.getElementById(`inputFile-container-${name}`)
    : document.querySelector(`[name="${name}"]`);
  element && element.scrollIntoView({ behavior: "smooth", block: "start" });
};

const TemplateInputSelector = ({
  currentStepFields,
  field,
  formik,
}: TemplateInputSelectorProps) => {
  const {
    labelInfo,
    optionalLabel,
    file: fileStyle,
    externalTooltip,
    anchorStyle,
  } = styles;

  const state = useAppSelector((state) => state);

  const {
    name,
    label,
    conditionalTooltip,
    anchor,
    tooltip,
    type,
    options,
    selectType,
    confirmSelection,
    disabled,
    placeholder,
    maxSize,
    validTypes,
    isNumberPercentage,
    minDatePickerDate,
    maxDatePickerDate,
    defaultSelectedKeys,
    canUploadMultipleFiles,
    infoText,
    infoTextPosition,
    onChange,
    onBlur,
    showExternalBox,
    conditional,
    conditionalFile,
    allConditionalsTrue,
    columns,
    autoSelect,
    country,
    conditionalValidation,
    validation,
    autoComplete,
    maxLength,
    dynamicOptions,
    disabledByUrlParams,
    showOptionalLabel,
    text,
  } = field;

  const {
    getFieldProps,
    values,
    setFieldValue,
    setFieldTouched,
    setFieldError,
    errors,
    touched,
    handleBlur,
  } = formik;

  const error = getNestedValue(errors, name);

  const touch = getNestedValue(touched, name);

  const value = getNestedValue(values, name);

  const [, setUploadedFiles] = useState<File[]>([]);
  const [disabledByUrl, setDisabledByUrl] = useState(false);

  const inputType = type;

  const segmentedOptions =
    type === "multiple-radio" && (options as SegmentedInputOption[]);

  const [searchParams] = useSearchParams();

  const getShouldConditionallyRender = () => {
    // If the field is shown conditionally by a file
    if (conditionalFile) {
      // If the file that is conditionally needed is present or the field has a value show the field
      return !!getNestedValue(values, conditionalFile.name) || !!value;
    }

    // If the field is shown conditionally by multiple fields
    if (conditional) {
      // If the file has a value dont hide the field
      if (inputType === "file" && !!value) {
        return true;
      }

      return getIsConditionallyTrue(values, conditional, allConditionalsTrue);
    }

    return true;
  };

  const dispatch = useAppDispatch();

  const getLabel = () => {
    if (
      (type !== "text" || (tooltip?.content && !tooltip.label)) &&
      type !== "dni" &&
      tooltip
    ) {
      return tooltip.label ? (
        <div className={"flex w-full justify-between items-center flex-wrap"}>
          <p>{label}</p>
          {tooltip.content ? (
            <Tooltip placement="top" content={tooltip.content} width={300}>
              <p className={labelInfo}>{tooltip.label}</p>
            </Tooltip>
          ) : (
            <p
              onClick={() => dispatch(openModal({ name: tooltip.modal }))}
              className={labelInfo}
            >
              {tooltip.label}
            </p>
          )}
        </div>
      ) : (
        <div
          className={`${optionalLabel} flex w-full gap-2 items-center flex-wrap`}
        >
          <p>{label}</p>
          <span>
            {(validation && isSchemaRequired(validation)) ||
            (conditionalValidation &&
              isSchemaRequired(conditionalValidation(state)))
              ? ""
              : " - Opcional"}
          </span>
          <Tooltip placement="top" content={tooltip.content} width={300}>
            <img src={tooltipIcon} alt="Tooltip" />
          </Tooltip>
        </div>
      );
    }

    return (
      <div className={optionalLabel}>
        {label}
        {showOptionalLabel && (
          <span>
            {(validation && isSchemaRequired(validation)) ||
            (conditionalValidation &&
              isSchemaRequired(conditionalValidation(state)))
              ? ""
              : " - Opcional"}
          </span>
        )}
      </div>
    );
  };

  const getConditionalTooltip = () => {
    let tooltipToShow: TooltipType | null = null;

    const valuesMatch = (
      value: string | (string | undefined)[] | File,
      conditionalValue: string
    ) =>
      Array.isArray(value)
        ? value
            .filter((v): v is string => v !== null)
            .includes(conditionalValue)
        : value === conditionalValue;

    if (Array.isArray(conditionalTooltip)) {
      tooltipToShow =
        conditionalTooltip.find((cond) =>
          valuesMatch(cond.value, getNestedValue(values, cond.name) as string)
        )?.tooltip || null;
    } else if (conditionalTooltip) {
      const conditionalValue = getNestedValue(
        values,
        conditionalTooltip.name
      ) as string;

      if (valuesMatch(conditionalTooltip.value, conditionalValue))
        tooltipToShow = conditionalTooltip.tooltip;
    }

    if (tooltipToShow) return getTooltip(tooltipToShow);

    return undefined;
  };

  const getTooltip = (tooltipToShow: TooltipType) => {
    if (tooltipToShow) {
      return tooltipToShow.label ? (
        <div>
          {tooltipToShow.content ? (
            <Tooltip
              placement="top"
              content={tooltipToShow.content}
              width={300}
            >
              <p className={labelInfo}>{tooltipToShow.label}</p>
            </Tooltip>
          ) : (
            <p
              onClick={() =>
                dispatch(openModal({ name: tooltipToShow?.modal }))
              }
              className={labelInfo}
            >
              {tooltipToShow.label}
            </p>
          )}
        </div>
      ) : (
        <Tooltip placement="top" content={tooltipToShow.content} width={300}>
          <img src={tooltipIcon} alt="Tooltip" />
        </Tooltip>
      );
    }
  };

  // To manage scroll to conditional fields
  const conditionalFieldsNames: ConditionalBool[] | undefined =
    currentStepFields
      ?.filter((field) => field.conditional || field.conditionalFile)
      .flatMap((field) => {
        if (field.conditionalFile) {
          return {
            boolField: field.conditionalFile?.name,
            conditionalField: field.name,
            dontScrollTo: Boolean(field.dontScrollTo),
          };
        } else if (Array.isArray(field.conditional)) {
          return field.conditional.map((conditional) => ({
            boolField: conditional.name,
            conditionalField: field.name,
            dontScrollTo: Boolean(field.dontScrollTo),
          }));
        }

        return {
          boolField: field.conditional?.name,
          conditionalField: field.name,
          dontScrollTo: Boolean(field.dontScrollTo),
        };
      });

  const conditionalBoolCurrentField = conditionalFieldsNames?.filter(
    (conditional) => conditional.boolField === name
  );

  const boolFieldsWithSameConditionalField = conditionalFieldsNames?.filter(
    (conditional) => {
      // If the current field is not a conditional field
      if (
        !conditionalBoolCurrentField ||
        conditionalBoolCurrentField.length === 0
      ) {
        return false;
      }

      // Filter the conditional fields that have the same conditional field
      if (conditionalBoolCurrentField[0].boolField === conditional.boolField) {
        return false;
      }

      // Return only the additional conditional fields that have the same conditional field
      return (
        conditional.conditionalField ===
        conditionalBoolCurrentField[0].conditionalField
      );
    }
  );

  const onFileUpload = (file: File) => {
    setUploadedFiles((prevFiles) => {
      const newFiles = [...prevFiles, file];
      canUploadMultipleFiles
        ? setFieldValue(name, newFiles)
        : setFieldValue(name, file);
      return newFiles;
    });

    if (conditionalBoolCurrentField) {
      setTimeout(() => {
        scrollToElementName(
          conditionalBoolCurrentField[0]?.conditionalField,
          true
        );
      }, 200);
    }
  };

  const onFileRemove = (fileToRemove?: File | FileData) => {
    if (!fileToRemove) throw new Error("File is required to remove it");

    setUploadedFiles((prevFiles) => {
      const newFiles = prevFiles.filter(
        (file) => !file || file !== fileToRemove
      );
      canUploadMultipleFiles
        ? setFieldValue(name, newFiles)
        : setFieldValue(name, null);
      return newFiles;
    });
  };

  useEffect(() => {
    if (disabledByUrlParams) {
      const foundParam = searchParams.get(disabledByUrlParams);
      if (foundParam) setDisabledByUrl(true);
    }
  }, [disabledByUrlParams]);

  //Allow to disable the field by a function, by url params or by a boolean
  const getDisabled = () => {
    return (
      (typeof disabled === "boolean" ? disabled : disabled?.(state, values)) ||
      disabledByUrl
    );
  };

  const isInputDisabled: boolean = getDisabled();

  const getInfoText = () => {
    //Info text can be a string or a function that returns a string for dynamic value
    if (infoText === undefined) return undefined;
    return typeof infoText === "string" ? infoText : infoText(value as string);
  };

  if (values && getShouldConditionallyRender()) {
    switch (type) {
      case "date-picker": {
        const minDatePickerDateValue = minDatePickerDate
          ? parseDate(moment(minDatePickerDate).format("YYYY-MM-DD"))
          : undefined;
        const maxDatePickerDateValue = maxDatePickerDate
          ? parseDate(moment(maxDatePickerDate).format("YYYY-MM-DD"))
          : undefined;

        const dateValue = value as string;

        return (
          <ColumnsWrapper columns={columns}>
            <NewDatePicker
              name={name}
              label={getLabel()}
              value={stringToCalendarDate(dateValue)}
              onChange={(date) => {
                if (date) {
                  formik.setFieldValue(
                    name,
                    calendarDateToString(date as CalendarDate)
                  );
                } else {
                  formik.setFieldValue(name, null);
                }
              }}
              onBlur={() => {
                setFieldTouched(name, true);
              }}
              isInvalid={!!error && !!touch}
              errorMessage={error as string}
              touched={!!touch}
              isDisabled={isInputDisabled}
              minValue={minDatePickerDateValue}
              maxValue={maxDatePickerDateValue}
              showMonthAndYearPickers
            />
          </ColumnsWrapper>
        );
      }
      case "select":
        return (options && options.length > 0) ||
          dynamicOptions ||
          (options && selectType === "single-radio-date-picker-day") ? (
          <ColumnsWrapper columns={columns}>
            <InputSelect
              key={name}
              label={getLabel()}
              type={selectType || "single"}
              minDatePickerDate={minDatePickerDate}
              maxDatePickerDate={maxDatePickerDate}
              items={
                options && options.length > 0
                  ? options
                  : dynamicOptions
                  ? dynamicOptions(formik)
                  : []
              }
              placeholder={placeholder}
              name={name}
              onChange={
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (value: any) => {
                  if (selectType === "single-radio-date-picker-day")
                    return null;

                  if (value.length === 0 && selectType?.includes("multiple")) {
                    setFieldValue(name, null);
                  } else {
                    setFieldValue(name, value);
                  }

                  conditionalResetAndScrollTo({
                    conditionalBoolCurrentField,
                    boolFieldsWithSameConditionalField,
                    values,
                    setFieldValue,
                    scrollToElementName,
                  });

                  if (field?.onChange) {
                    field.onChange(
                      values,
                      setFieldValue,
                      setFieldError,
                      setFieldTouched,
                      value,
                      state,
                      dispatch
                    );
                  }
                }
              }
              onBlur={() => {
                setFieldTouched(name, true);
              }}
              onDatepickerDateChanged={(date) =>
                setFieldValue(name, moment(date[0]).format("YYYY-MM-DD"))
              }
              // If no date picker or
              // If date picker and no value or empty array
              // Assign the value to the input value so it can be reseted
              {...((!selectType?.includes("date-picker") ||
                (selectType?.includes("date-picker") &&
                  (!value ||
                    (Array.isArray(value) && value.length === 0)))) && {
                inputValue: value as string,
              })}
              defaultSelectedKeys={defaultSelectedKeys}
              {...(!!value && {
                initialDatePickerRange: [
                  moment(value as string).toDate(),
                  moment(value as string).toDate(),
                ],
              })}
              isInvalid={!!error && !!touch}
              errorMessage={error as string}
              confirmSelection={confirmSelection}
              showExternalBox={!!showExternalBox}
              disabled={isInputDisabled}
              isDisabled={isInputDisabled}
              touched={!!touch}
            />
          </ColumnsWrapper>
        ) : null;
      case "radio":
        return options && options.length > 0 ? (
          <ColumnsWrapper columns={columns}>
            <RadioGroup
              key={name}
              label={getLabel()}
              options={options}
              disabled={isInputDisabled}
              {...getFieldProps(name)}
              onChange={(e) => {
                onChange && onChange(values, setFieldValue);
                formik.handleChange(e);
                conditionalResetAndScrollTo({
                  conditionalBoolCurrentField,
                  boolFieldsWithSameConditionalField,
                  values,
                  setFieldValue,
                  scrollToElementName,
                });
              }}
            />
          </ColumnsWrapper>
        ) : null;
      case "phone":
        return (
          <ColumnsWrapper columns={columns}>
            <InputPhone
              specialLabel={label}
              key={name}
              infoMessage={getInfoText()}
              {...getFieldProps(name)}
              onChange={(value) => {
                setFieldValue(name, value);
              }}
              errorMessage={error as string}
              touched={!!touch}
              isValid={!!(touch && value && !error)}
              countryCodeEditable={false}
              preferredCountries={["ar", "es", "uy"]}
              label={label}
            />
          </ColumnsWrapper>
        );
      case "file":
        return (
          <ColumnsWrapper columns={columns}>
            <InputFile
              name={name}
              key={name}
              maxSize={maxSize || 0}
              validTypes={validTypes || []}
              multiple={canUploadMultipleFiles}
              text={
                <p>
                  Arrastrá o{" "}
                  <span>
                    seleccioná{" "}
                    {canUploadMultipleFiles ? "archivos" : "un archivo"}
                  </span>{" "}
                  de tu dispositivo
                </p>
              }
              maxFiles={5}
              isOptional={
                (validation && !isSchemaRequired(validation)) ||
                (conditionalValidation &&
                  !isSchemaRequired(conditionalValidation(state)) &&
                  !conditionalFile)
              }
              tooltip={tooltip ? getTooltip(tooltip) : getConditionalTooltip()}
              anchor={
                anchor && (
                  <div className={anchorStyle} onClick={anchor.onClick}>
                    <p>{anchor.text}</p>
                  </div>
                )
              }
              className={fileStyle}
              label={label}
              infoText={infoText as string}
              infoTextPosition={infoTextPosition}
              onFileUpload={onFileUpload}
              onFileRemove={onFileRemove}
              initialValue={value as File}
            />
          </ColumnsWrapper>
        );
      case "token":
        return (
          <ColumnsWrapper columns={columns}>
            <p key={name}>Add InputToken</p>
          </ColumnsWrapper>
        );
      case "multiple-radio":
        return segmentedOptions && segmentedOptions.length > 0 ? (
          <ColumnsWrapper columns={columns}>
            <SegmentedOptionsInput
              key={name}
              label={label}
              options={segmentedOptions}
              isInvalid={!touched && !value}
              {...getFieldProps(name)}
            />
          </ColumnsWrapper>
        ) : null;
      case "textarea":
        return (
          <ColumnsWrapper columns={columns}>
            <InputTextArea
              key={name}
              label={label}
              type={inputType}
              isInvalid={!!error && !!touch}
              errorMessage={error as string}
              isDisabled={isInputDisabled}
              placeholder={placeholder}
              maxLength={maxLength}
              {...getFieldProps(name)}
            />
          </ColumnsWrapper>
        );
      case "address": {
        return (
          <ColumnsWrapper columns={columns}>
            <InputAddress
              {...getFieldProps(name)}
              onBlur={(e) => {
                handleBlur(e);
              }}
              key={name}
              label={label}
              isInvalid={!!error && !!touch}
              errorMessage={error as string}
              disabled={isInputDisabled}
              placeholder={placeholder}
              onValueChange={async (address) => {
                if (!address) {
                  setFieldValue(name, "");
                  return;
                }
                onChange && onChange(address, setFieldValue);
              }}
              country={country && (getNestedValue(values, country) as string)}
              autoSelect={
                autoSelect && (getNestedValue(values, autoSelect) as string)
              }
              autoComplete={autoComplete}
              onChange={formik.handleChange}
              touched={!!touch}
            />
          </ColumnsWrapper>
        );
      }
      case "dni":
      case "cuit":
      case "text":
      case "email":
      case "number":
        return (
          <ColumnsWrapper columns={columns} key={name}>
            {tooltip && tooltip.external && (
              <div
                className={externalTooltip}
                onClick={() => dispatch(openModal({ name: tooltip.modal }))}
              >
                {tooltip.label}
              </div>
            )}
            <Input
              {...getFieldProps(name)}
              label={getLabel()}
              type={inputType as InputProps["type"]}
              isInvalid={!!error && !!touch}
              errorMessage={error as string}
              isDisabled={isInputDisabled}
              placeholder={placeholder}
              initialValue={value as string}
              isNumberPercentage={isNumberPercentage}
              maxLength={maxLength}
              infoMessage={getInfoText()}
              touched={!!touch}
              {...getFieldProps(name)}
              {...(onChange && {
                onChange: (element) => {
                  formik.handleChange(element);
                  return onChange(
                    values,
                    setFieldValue,
                    setFieldError,
                    setFieldTouched,
                    element
                  );
                },
              })}
              onBlur={(e) => {
                handleBlur(e);
                onBlur &&
                  onBlur(
                    values,
                    setFieldValue,
                    setFieldError,
                    setFieldTouched,
                    e,
                    state
                  );
              }}
              {...(tooltip &&
                !tooltip?.external && {
                  info: "CUSTOM",
                  customInfo:
                    tooltip.label &&
                    (tooltip.content ? (
                      <Tooltip
                        placement="top"
                        content={tooltip.content}
                        width={300}
                      >
                        <p className={labelInfo}>{tooltip.label}</p>
                      </Tooltip>
                    ) : (
                      <p
                        onClick={() =>
                          dispatch(openModal({ name: tooltip.modal }))
                        }
                        className={labelInfo}
                      >
                        {tooltip.label}
                      </p>
                    )),
                })}
            />
          </ColumnsWrapper>
        );
      case "label":
        return (
          <ColumnsWrapper columns={columns} key={name}>
            {text}
          </ColumnsWrapper>
        );
    }
  } else {
    return null;
  }
};

export default TemplateInputSelector;
