import { doc, getFirestore, setDoc } from "@firebase/firestore";
import { FirestoreLivenessData } from "../interfaces/types";
import { ReactNode } from "react";
import { getCompanyData } from "../../../utils";

export const livenessStep = {
  name: "Identidad",
  step: 1,
};

export const saveLivenessDataToFirestore = ({
  gender,
  dni,
  email,
  attemptsDesktop,
  attemptsMobile,
  scannedQR,
  isValid,
  uuid,
  facialRecognition,
  faceLivenessDetection,
  documentName,
  documentId,
}: FirestoreLivenessData) => {
  const db = getFirestore();
  const livenessRef = doc(
    db,
    documentName ?? "livenessValidation",
    documentId ?? uuid
  );
  setDoc(
    livenessRef,
    {
      gender,
      dni,
      email,
      attemptsDesktop,
      attemptsMobile,
      scannedQR,
      isValid,
      facialRecognition,
      faceLivenessDetection,
      documentName,
      documentId,
    },
    { merge: true }
  );
};

export const getErrorData = ({
  error,
  reachedAttemptsLimit,
  handleRequirementsClick,
}: {
  error: string | null;
  reachedAttemptsLimit: boolean;
  handleRequirementsClick: () => void;
}) => {
  let errorType: "WARNING" | "ERROR" = "ERROR";
  let errorMessage: ReactNode;

  if (reachedAttemptsLimit) {
    errorType = "ERROR";
    errorMessage = (
      <span>
        Lo sentimos, no pudimos reconocerte.
        <br></br>
        <a
          href={`https://wa.me/${getCompanyData().phone}`}
          rel="noreferrer"
          target="_blank"
        >
          Contactá a soporte{" "}
        </a>
        para continuar.
      </span>
    );
    return { errorType, errorMessage };
  }

  switch (error) {
    case "CAMERA_FRAMERATE_ERROR":
      errorType = "WARNING";
      errorMessage = (
        <span>
          Tu cámara o buscador de internet no cumple los{" "}
          <p onClick={handleRequirementsClick}>requisitos mínimos</p>
        </span>
      );
      break;
    case "CONNECTION_TIMEOUT":
      errorType = "WARNING";
      errorMessage = <span>Buscá un lugar con mejor conexión.</span>;
      break;
    case "TIMEOUT":
      errorMessage = (
        <span>
          Lo sentimos, no pudimos reconocerte. Rellená el óvalo con tu cara.
        </span>
      );
      break;
    case "FACE_DISTANCE_ERROR":
      errorMessage = (
        <span>
          Lo sentimos, no pudimos reconocerte. Asegurate de no acercarte durante
          la captura.
        </span>
      );
      break;
    default:
      errorMessage = (
        <span>
          Lo sentimos, no pudimos reconocerte.
          <br></br>
          Por favor, intentalo nuevamente.
        </span>
      );
  }

  return { errorType, errorMessage };
};

export const livenessTexts = {
  waitingCameraPermissionText: "Esperando el acceso a tu cámara",
  startScreenBeginCheckText: "Comenzar captura",
  hintConnectingText: "Conectando...",
  hintVerifyingText: "Verificando...",
  hintCheckCompleteText: "Completado",
  goodFitCaptionText: "Mantené tu posición",
  goodFitAltText: "Encaja perfectamente en el óvalo",
  hintTooCloseText: "Alejate",
  hintTooFarText: "Acercate",
  hintCenterFaceText: "Centrá tu cara",
  hintFaceOffCenterText: "Centrá tu cara",
  hintMoveFaceFrontOfCameraText: "Movete enfrente de la cámara",
  hintTooManyFacesText: "Asegurate que haya solo una cara",
  hintFaceDetectedText: "Cara detectada",
  hintCanNotIdentifyText: "Movete enfrente de la cámara",
  hintIlluminationTooDarkText: "Busca un lugar con mejor iluminación",
  hintIlluminationTooBright: "Busca un lugar con menos luz",
  hintHoldFaceForFreshnessText: "Mantené tu posición",
  hintMatchIndicatorText: "50% completado. Acercate",
  tryAgainText: "Reintentar",
  retryCameraPermissionsText: "Reintentar",
  cameraNotFoundHeadingText: "Permití el acceso a tu cámara",
};
