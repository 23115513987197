import {
  BasicStep,
  CompanyType,
  ComponentStep,
  Field,
  PreconfirmationField,
  ShareholderData,
  Step,
} from "../types";
import logoAllariaMas from "../assets/images/logos/allariamas.svg";
import logoAllaria from "../assets/images/logos/allaria.svg";
import logoAgroFinanzas from "../assets/images/logos/agrofinanzas.svg";
import faviconAllariaMas from "../assets/favicon.png";
import faviconAllaria from "../assets/faviconAllaria.png";
import { FormikValues } from "formik";

const staticUrl = import.meta.env.VITE_BASE_URL_STATIC;

// Function to check if a Step is a BasicStep
export const isBasicStep = (
  step?: Step | BasicStep | ComponentStep
): step is BasicStep => {
  // Check if step is defined and does not have a component property
  return step !== undefined && ("fields" in step || "subSteps" in step);
};

// Function to check if a Step is a ComponentStep
export const isComponentStep = (
  step?: Step | BasicStep | ComponentStep
): step is ComponentStep => {
  // Check if step is defined and has a component property
  return step !== undefined && "component" in step;
};

export const getOnboardingFields = (steps: Step[]) => {
  const getFieldsByStep = (step: Step) => {
    let fields: Field[] = [];

    if (step.fields) {
      fields = step.fields;
    } else if (step.subSteps) {
      fields = step.subSteps.flatMap(
        (subStep: BasicStep) => subStep.fields ?? []
      );
    }

    return fields;
  };

  const onboardingFields = steps.flatMap((step) => getFieldsByStep(step));
  return onboardingFields;
};

export const getPreconfirmationStepFieldsByName = (fields: Field[]) => {
  const formattedFields = getFormattedPreconfirmationFields(fields);

  const fieldsByName: Record<string, PreconfirmationField> =
    formattedFields.reduce((acc, field) => {
      return { ...acc, [field.name]: field };
    }, {});

  return fieldsByName;
};

export const getFormattedPreconfirmationFields = (
  fields: Field[],
  values: FormikValues | null = null
) => {
  return fields.map(
    ({
      name,
      label,
      type,
      options,
      conditional,
      preconfirmationLabel,
      formatInPreConfirmation,
      valueGetter,
      canUploadMultipleFiles,
    }) => ({
      name,
      label: preconfirmationLabel ?? label,
      type,
      options,
      isConditional: !!conditional,
      formatInPreConfirmation,
      values,
      valueGetter,
      canUploadMultipleFiles,
    })
  );
};

export const getCompanyData = () => {
  const company = import.meta.env.VITE_COMPANY as CompanyType;
  const staticUrl = import.meta.env.VITE_BASE_URL_STATIC;

  switch (company) {
    case "ALLARIA":
      return {
        formTitle: "Onboarding Allaria",
        logo: logoAllaria,
        favicon: faviconAllaria,
        companyName: company,
        termsAndConditions: [
          {
            name: "termsAndConditions",
            required: true,
            label: (
              <div>
                Acepto los{" "}
                <span id="terms-and-conditions">Términos y Condiciones</span>
                {" y "}
                <span id="platform-use">Uso de la plataforma</span>
              </div>
            ),
            links: [
              {
                id: "terms-and-conditions",
                name: "Términos y Condiciones de Allaria",
                url: `${staticUrl}/terms/T%26C+Allaria+con+A%2B.pdf`,
              },
              {
                id: "platform-use",
                name: "Uso de la plataforma",
                url: `${staticUrl}/terms/Términos+y+condiciones+de+Uso+de+la+Plataforma.pdf`,
              },
            ],
          },
          {
            required: false,
            name: "senebiAndExteriorNotes",
            label: (
              <div>
                Acepto la <span id="senebi-note">Nota SENEBI</span> y{" "}
                <span id="exterior-note">Nota exterior</span>
              </div>
            ),
            links: [
              {
                id: "senebi-note",
                name: "Nota SENEBI",
                url: `${staticUrl}/terms/Nota+SENEBI.pdf`,
              },
              {
                id: "exterior-note",
                name: "Nota exterior",
                url: `${staticUrl}/terms/Nota+de+operaciones+en+el+exterior.pdf`,
              },
            ],
          },
        ],
        phone: "5491140411111",
        mail: "atencionalcliente@allaria.com.ar",
      };
    case "AGROFINANZAS":
      return {
        formTitle: "Onboarding Agro Finanzas",
        logo: logoAgroFinanzas,
        logoClassname: "agroFinanzasLogo",
        favicon: faviconAllariaMas,
        companyName: company,
        termsAndConditions: [
          {
            required: true,
            name: "termsAndConditions",
            label: (
              <div>
                Acepto los{" "}
                <span id="terms-and-conditions">Términos y Condiciones</span>
              </div>
            ),
            links: [
              {
                id: "terms-and-conditions",
                name: "Términos y Condiciones Agrofinanzas",
                url: `${staticUrl}/terms/Convenio+y+mandato+AAF.pdf`,
              },
            ],
          },
          {
            required: false,
            name: "wantsAllariaAccount",
            label: (
              <div>
                Quiero una comitente Allaria. Ver{" "}
                <span id="allaria-account">Términos y Condiciones</span>
              </div>
            ),
            links: [
              {
                id: "allaria-account",
                name: "Términos y Condiciones de Allaria",
                url: `${staticUrl}/terms/T%26C+Allaria+con+A%2B.pdf`,
              },
            ],
          },
          {
            required: false,
            name: "senebiAndExteriorNotes",
            label: (
              <div>
                Acepto la <span id="senebi-note">Nota SENEBI</span> y{" "}
                <span id="exterior-note">Nota exterior</span>
              </div>
            ),
            links: [
              {
                id: "senebi-note",
                name: "Nota SENEBI",
                url: `${staticUrl}/terms/11.Instrucción+op+SENEBI+-Mayo+23.pdf`,
              },
              {
                id: "exterior-note",
                name: "Nota exterior",
                url: `${staticUrl}/terms/10.Instrucción+op+Exterior+-Mayo+23.pdf`,
              },
            ],
          },
        ],
        phone: "5491138499171",
        mail: "soporte@allariaagro.com.ar",
      };
    case "ALLARIAMAS":
    default:
      return {
        formTitle: "Onboarding Allaria+",
        logo: logoAllariaMas,
        favicon: faviconAllariaMas,
        companyName: company,
        termsAndConditions: [
          {
            required: true,
            name: "termsAndConditions",
            label: (
              <div>
                Acepto los{" "}
                <span id="terms-and-conditions">Términos y Condiciones</span>
                {" y "}
                <span id="platform-use">Uso de la plataforma</span>
              </div>
            ),
            links: [
              {
                id: "terms-and-conditions",
                name: "Términos y Condiciones de Allaria+",
                url: `${staticUrl}/terms/2)+TyC+Allaria%2B+Cuenta+%2B+Anexo+I+sin+aclaracion+(web+y+OB+Allaria%2B).pdf`,
              },
              {
                id: "platform-use",
                name: "Uso de la plataforma",
                url: `${staticUrl}/terms/Términos+y+condiciones+de+Uso+de+la+Plataforma.pdf`,
              },
            ],
          },
        ],
        phone: "5491138499171",
        mail: "soporte@allariamas.com.ar",
      };
  }
};

export const juridicalDocumentLinks = {
  "businessDocumentation.membersNote": `${staticUrl}/docs/4-+MODELO+ENTIDADES-AGRUPACIONES.docx`,
  "businessDocumentation.trustNote": `${staticUrl}/docs/2-+MODELO+FIDEICOMISO.docx`,
  "businessDocumentation.shareholdersNote": `${staticUrl}/docs/1-+MODELO+DDJJ+ACCIONISTAS+Y+BF.docx`,
  "businessDocumentation.partnersNote": `${staticUrl}/docs/3-+MODELO+SGR.doc`,
};

// Variables for support contact
export const PHONE_NUMBER = getCompanyData().phone;
export const WHATSAPP_LINK = `https://wa.me/${PHONE_NUMBER}`;
export const MAIL = getCompanyData().mail;

// Generic content to show for toastify error messages
export const genericToastifyError = (
  <div>
    Lo sentimos, algo falló inesperadamente. Intentalo nuevamente y si el error
    persiste, contactate con{" "}
    <a href={WHATSAPP_LINK} target="_blank" rel="noreferrer">
      <b>Soporte.</b>
    </a>
  </div>
);

export const customToastifyError = (message: string) => {
  return <div>{message}</div>;
};

// Styles for QR Image
export const qrStyle = {
  color: {
    dark: "#224288",
  },
  margin: 0,
  width: 200,
};

export const validDniTypes = [
  "image/jpeg",
  "image/png",
  "image/heic",
  "image/heif",
  "application/pdf",
];

export const validBusinessDocumentationTypes = [
  "application/msword", //.doc
  "application/pdf",
  "image/jpeg",
  "image/png",
];

export const maxFileSize = 10; // MB

/**
 * Convert a string value to a CUIT format
 * @param {string} value - The value to be converted to CUIT format
 * @returns {string} - The value in CUIT format
 */
export const asCuit = (value: string) =>
  value.slice(0, 2) + "-" + value.slice(2, 10) + "-" + value.slice(10, 11);

export const asDni = (value: string) => {
  // Allow 7 or 8 digits
  let valueWithMask = value;

  if (value.length >= 2 && value.length <= 7) {
    valueWithMask = `${value.slice(0, 1)}.${value.slice(1, 4)}.${value.slice(
      4,
      7
    )}`;
  } else if (value.length >= 3 && value.length > 7) {
    valueWithMask = `${value.slice(0, 2)}.${value.slice(2, 5)}.${value.slice(
      5,
      8
    )}`;
  }

  if (valueWithMask.endsWith(".")) {
    return valueWithMask.slice(0, -1);
  } else {
    return valueWithMask;
  }
};

export const scrollToElement = (id: string) => {
  const element = document.getElementById(id);
  element && element.scrollIntoView({ behavior: "smooth", block: "start" });
};

export const cities = [
  "Provincia de Buenos Aires",
  "CABA",
  "Catamarca",
  "Chaco",
  "Chubut",
  "Córdoba",
  "Corrientes",
  "Entre Ríos",
  "Formosa",
  "Jujuy",
  "La Pampa",
  "La Rioja",
  "Mendoza",
  "Misiones",
  "Neuquén",
  "Río Negro",
  "Salta",
  "San Juan",
  "San Luis",
  "Santa Cruz",
  "Santa Fe",
  "Santiago del Estero",
  "Tierra del Fuego",
  "Tucumán",
];

export const cityOptions = () =>
  cities.map((city) => ({ value: city, label: city }));

//Backend not support "Ciudad Autónoma de Buenos Aires", so we parse it to "CABA"
export const parseStateAddress = (state: string) => {
  if (state === "Ciudad Autónoma de Buenos Aires") return "CABA";
  if (
    [
      "provincia de buenos aires",
      "buenos aires",
      "bs as",
      "bs.as.",
      "bs. as.",
    ].includes(state.toLowerCase())
  )
    return "Provincia de Buenos Aires";
  else return state;
};

export const hasJointSignature = (formValues: FormikValues | null) => {
  if (!formValues) return false;

  return formValues?.businessInformation?.hasJointSignature === "YES";
};

export const isToros = (formValues: FormikValues | null) => {
  if (!formValues) return false;

  return formValues?.referrer === "Toros Capital";
};

export const isWina = (formValues: FormikValues | null) => {
  if (!formValues) return false;

  return formValues?.referrer === "Wina";
};

export const isLandToken = (formValues: FormikValues | null) => {
  if (!formValues) return false;

  return formValues?.referrer === "Landtoken";
};

export const isLaBragadense = (formValues: FormikValues | null) => {
  if (!formValues) return false;

  return formValues?.referrer === "Labragadense";
};

export const isCompanyWithoutShareholders = (corporateType: string) =>
  [
    "Asociación / Cámara / Cooperativa / Fundación / Mutual",
    "Consorcio",
    "Fideicomiso",
    "Organismo y/o Entidad Gubernamental",
  ].includes(corporateType);

// Helper function to convert booleans to "SI"/"NO" or keep null
export const convertBoolean = (value: boolean | null): string | null => {
  return value === null ? null : value ? "SI" : "NO";
};

export const isUncompletedShareholder = (shareholder: ShareholderData) => {
  if (!shareholder) return true;

  const isMarried =
    shareholder?.personType === "PHYSICAL" &&
    shareholder.civilStatus === "MARRIED";

  if (isMarried && (!shareholder.spouseName || !shareholder.spouseLastName)) {
    return true;
  }
  return Object.entries(shareholder).some(([key, value]) => {
    if (!value) {
      if (isMarried) {
        // Si está casado, spouseName y spouseLastName también deben considerarse incompletos si están vacíos
        return key !== "pepDetails" && key !== "spouseCuil";
      } else {
        return (
          key !== "spouseName" &&
          key !== "spouseLastName" &&
          key !== "spouseCuil" &&
          key !== "pepDetails"
        );
      }
    }
    return false;
  });
};

export const isAllariaOnboarding = () => {
  const company = import.meta.env.VITE_COMPANY as CompanyType;
  return company === "ALLARIA";
};

export const isAllariaMasOnboarding = () => {
  const company = import.meta.env.VITE_COMPANY as CompanyType;
  return company === "ALLARIAMAS";
};
